import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import {useHistory} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {updateDocumentTitle} from "../../Utils/Common";
import Map from "../Map/Map";

const ClientDetails = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState({title: ''});
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const [requestsListOngoing, setRequestListOngoing] = useState([]);
    const [requestsListAll, setRequestListAll] = useState([]);
    const [businessList, setBusinessList] = useState([]);

    const [ratesList, setRatesList] = useState([]);
    const [requestType, setRequestType] = useState('');
    const [meansType, setMeansType] = useState('');
    const [peakRate, setPeakRate] = useState('');
    const [normalRate, setNormalRate] = useState('');

    const columns = [
        {
            name: '#',
            sortable: true,
            cell: row => <div><Link to={"/request/" + row.ID}>{row.request_code}</Link></div>,
        },
        {
            name: 'Type',
            selector: 'pick_up_type',
            sortable: true,
        },
        {
            name: 'Pick up',
            selector: 'pick_up_location',
            sortable: true,
        },
        {
            name: 'Delivery type',
            selector: 'delivery_type',
            sortable: true,
        },
        {
            name: 'Means',
            selector: 'means',
            sortable: true,
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
        },
        {
            name: 'Fee',
            selector: 'fee',
            sortable: true,
        }
    ]

    const businessColumns = [
        {
            name: 'Name',
            sortable: true,
            cell: row => <div><Link to={"/business/" + row.ID}>{row.name}</Link></div>,
        },
        {
            name: 'Contact email',
            selector: 'contact_email',
        },
        {
            name: 'Contact phone',
            selector: 'contact_phone',
        },
        {
            name: 'Client Status',
            cell: row => row.active === 1 ? <span>Active</span> : <span>Inactive</span>,
        },
        {
            name: 'Admin Status',
            cell: row => row.admin_active === 1 ? <span>Active</span> : <span>Inactive</span>,
        }
    ]

    const {id} = props.match.params;

    const load = () => {
        let data = new FormData();
        data.append("id", id);

        setIsLoading(true);
        postData(Endpoints.CLIENT_DETAIL, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setItem(res.data);
                    setName(res.data.name);
                    setPhone(res.data.phone);
                    setEmail(res.data.email);
                    updateDocumentTitle(res.data.name)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    useEffect(() => {
        load()
        loadRequests(false, "ongoing")
        loadRequests(false, "completed")
        loadBusinesses(false)
    }, []);

    function updateStatus(number) {
        let data = new FormData();
        data.append("id", id);
        data.append("status", number);

        setIsLoading(true);
        postData(Endpoints.CLIENT_UPDATE_STATUS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(SUCCESS ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);

                if (res.success === 1) {
                    load()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    const loadRequests = (loadMore, status) => {
        let data = new FormData();
        data.append("client_id", id);
        data.append("status", status);
        data.append("offset", status === "ongoing" ? requestsListOngoing.length : requestsListAll.length);

        setIsLoading(true);

        postData(Endpoints.REQUEST_LIST_USER, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (status === "ongoing") {
                    let currentPosts = loadMore ? requestsListOngoing : [];
                    currentPosts = currentPosts.concat(...res.data);
                    setRequestListOngoing(currentPosts);
                } else {
                    let currentPosts = loadMore ? requestsListAll : [];
                    currentPosts = currentPosts.concat(...res.data);
                    setRequestListAll(currentPosts);
                }

            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    const loadBusinesses = (loadMore) => {
        let data = new FormData();
        data.append("client_id", id);
        data.append("offset", businessList.length);

        setIsLoading(true);

        postData(Endpoints.BUSINESS_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                let currentPosts = loadMore ? businessList : [];
                currentPosts = currentPosts.concat(...res.data);
                setBusinessList(currentPosts);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };


    function loadRates() {
        let data = new FormData();
        data.append("user_id", id);
        setIsLoading(true)
        postData(Endpoints.RATE_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setRatesList(res.data || []);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    return (
        <AuthProvider>
            {item.title !== '' &&
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>{item.name}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-center">
                                                    {isLoading &&
                                                    <ProgressView size={23} style={{color: Colors.black, margin: 10}}/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    {!isLoading &&
                                                    <button type="submit"
                                                            className={item.status === 1 ? "btn btn-danger" : "btn btn-success"}
                                                            onClick={e => updateStatus(item.status === 1 ? 0 : 1)}>{item.status === 1 ? "Deactivate" : "Activate"}</button>
                                                    }
                                                </div>
                                            </div>
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    <Link to="/clients"
                                                          className="btn btn-success-outline">
                                                        Back to clients
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabDetails" role="tab" data-toggle="tab">
                                        Details
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabRequestsOngoing" role="tab" data-toggle="tab">
                                        Ongoing requests
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabRequestsAll" role="tab" data-toggle="tab">
                                        Completed requests
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabReviews" role="tab" data-toggle="tab">
                                        Client reviews
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabBusinesses" role="tab" data-toggle="tab">
                                        Businesses
                                    </a>
                                </li>
                                {/*<li className="nav-item">*/}
                                {/*    <a className="nav-link" href="#tabRates" role="tab" data-toggle="tab">*/}
                                {/*        Rates & Charges*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabDetails">
                                <div className="row">
                                    <div className="col-md-7">
                                        <table className="mb-3 table table-bordered table-hover table-sm dataT">
                                            <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td className="color-black-blue">
                                                    <b>{item.name}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td className="color-black-blue">
                                                    <b>{item.email}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Phone</td>
                                                <td className="color-black-blue">
                                                    <b>{item.phone}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Status</td>
                                                <td className="color-black-blue">
                                                    <b>{item.status === 0 ? "INACTIVE" : "ACTIVE"}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Registered on</td>
                                                <td className="color-black-blue">
                                                    <b>{item.date_added}</b></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade in" id="tabRequestsOngoing">
                                <DataTable
                                    columns={columns}
                                    data={requestsListOngoing}
                                    pointerOnHover={true}
                                    className={"table table-striped table-bordered"}
                                    noHeader={true}
                                    onRowClicked={e => {
                                        history.push('/request/' + e.ID);
                                    }}
                                    customStyles={Styles.customTableStyles}
                                />
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabRequestsAll">
                                <DataTable
                                    columns={columns}
                                    data={requestsListAll}
                                    pointerOnHover={true}
                                    className={"table table-striped table-bordered"}
                                    noHeader={true}
                                    onRowClicked={e => {
                                        history.push('/request/' + e.ID);
                                    }}
                                    customStyles={Styles.customTableStyles}
                                />
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabBusinesses">
                                <DataTable
                                    columns={businessColumns}
                                    data={businessList}
                                    pointerOnHover={true}
                                    className={"table table-striped table-bordered"}
                                    noHeader={true}
                                    onRowClicked={e => {
                                        history.push('/business/' + e.ID);
                                    }}
                                    customStyles={Styles.customTableStyles}
                                />
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabReviews">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table id="table" className="display table table-striped table-bordered"
                                               cellSpacing="0"
                                               width="100%">
                                            <thead>
                                            <tr>
                                                <th>Request</th>
                                                <th>Pick up location</th>
                                                <th>Client review</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {requestsListAll.map((i, k) => <tr>
                                                    <td><Link to={"/request/" + i.ID}>#{i.request_code}</Link></td>
                                                    <td>{i.pick_up_location}</td>
                                                    <td>
                                                        <div>
                                                            <b>Ratings</b> : {i.customer_rating} / 5<br/>
                                                            <b>Review</b> : {i.customer_feedback}<br/>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>

                                        </table>

                                    </div>
                                </div>
                            </div>


                            {/*<div role="tabpanel" className="tab-pane fade in" id="tabRates">*/}
                            {/*    <div className="row">*/}
                            {/*        <div className="col-md-8">*/}
                            {/*            <DataTable*/}
                            {/*                columns={ratesColumns}*/}
                            {/*                data={ratesList}*/}
                            {/*                pointerOnHover={true}*/}
                            {/*                className={"table table-striped table-bordered"}*/}
                            {/*                noHeader={true}*/}
                            {/*                customStyles={Styles.customTableStyles}*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*        <div className="col-md-4">*/}
                            {/*            <section*/}
                            {/*                className="box-typical box-typical-dashboard panel panel">*/}
                            {/*                <header className="box-typical-header panel-heading bg-dark">*/}
                            {/*                    <h3 className="panel-title text-white">Add new rate</h3>*/}
                            {/*                </header>*/}

                            {/*                <div className="card-block">*/}
                            {/*                    <form onSubmit={addRates}>*/}
                            {/*                        <div className="form-group">*/}
                            {/*                            <label*/}
                            {/*                                className="form-label col-form-label-sm">Select*/}
                            {/*                                request type</label>*/}
                            {/*                            <select className="form-control"*/}
                            {/*                                    value={requestType}*/}
                            {/*                                    onChange={({target}) => setRequestType(target.value)}>*/}
                            {/*                                {requestTypeList.map((i, k) => <option*/}
                            {/*                                    value={i.name}>{i.name}</option>*/}
                            {/*                                )}*/}
                            {/*                            </select>*/}
                            {/*                        </div>*/}

                            {/*                        <div className="form-group">*/}
                            {/*                            <label*/}
                            {/*                                className="form-label col-form-label-sm">Select*/}
                            {/*                                means type</label>*/}
                            {/*                            <select className="form-control"*/}
                            {/*                                    value={meansType}*/}
                            {/*                                    onChange={({target}) => setMeansType(target.value)}>*/}
                            {/*                                {meansTypeList.map((i, k) => <option*/}
                            {/*                                    value={i.name}>{i.name}</option>*/}
                            {/*                                )}*/}
                            {/*                            </select>*/}
                            {/*                        </div>*/}

                            {/*                        <div className="form-group">*/}
                            {/*                            <label*/}
                            {/*                                className="form-label col-form-label-sm">Normal rate*/}
                            {/*                                per KM in KES</label>*/}
                            {/*                            <input type="number"*/}
                            {/*                                   required={true}*/}
                            {/*                                   className="form-control"*/}
                            {/*                                   value={normalRate}*/}
                            {/*                                   onChange={e => setNormalRate(e.target.value)}*/}
                            {/*                            />*/}
                            {/*                        </div>*/}

                            {/*                        <div className="form-group">*/}
                            {/*                            <label*/}
                            {/*                                className="form-label col-form-label-sm">Peak rate*/}
                            {/*                                per KM in KES</label>*/}
                            {/*                            <input type="number"*/}
                            {/*                                   required={true}*/}
                            {/*                                   className="form-control"*/}
                            {/*                                   value={peakRate}*/}
                            {/*                                   onChange={e => setPeakRate(e.target.value)}*/}
                            {/*                            />*/}
                            {/*                        </div>*/}

                            {/*                        <div className="form-group">*/}
                            {/*                            {isLoading ?*/}
                            {/*                                <ProgressView size={23}*/}
                            {/*                                              style={{*/}
                            {/*                                                  color: Colors.black,*/}
                            {/*                                                  marginTop: 5*/}
                            {/*                                              }}/> :*/}
                            {/*                                <button type="submit" className="btn btn-primary"*/}
                            {/*                                >Add</button>*/}
                            {/*                            }*/}
                            {/*                        </div>*/}
                            {/*                    </form>*/}
                            {/*                </div>*/}
                            {/*            </section>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*</div>*/}

                        </div>
                    </section>

                </div>

            </div>
            }
        </AuthProvider>
    )
};


export default ClientDetails