import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import {useHistory} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {updateDocumentTitle} from "../../Utils/Common";
import Map from "../Map/Map";
import ListRatesComponent from "../Views/ListRatesComponent";
import AddRatesComponent from "../Views/AddRatesComponent";

const DepartmentDetails = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState({name: ''});
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const [requestsListOngoing, setRequestListOngoing] = useState([]);
    const [requestsListAll, setRequestListAll] = useState([]);
    const [businessList, setBusinessList] = useState([]);

    const columns = [
        {
            name: '#',
            sortable: true,
            cell: row => <div><Link to={"/request/" + row.ID}>{row.request_code}</Link></div>,
        },
        {
            name: 'Type',
            selector: 'pick_up_type',
            sortable: true,
        },
        {
            name: 'Pick up',
            selector: 'pick_up_location',
            sortable: true,
        },
        {
            name: 'Delivery type',
            selector: 'delivery_type',
            sortable: true,
        },
        {
            name: 'Means',
            selector: 'means',
            sortable: true,
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
        },
        {
            name: 'Fee',
            selector: 'fee',
            sortable: true,
        }
    ]

    const businessColumns = [
        {
            name: 'Email',
            sortable: true,
            cell: row => <div><Link to={"/account/" + row.ID}>{row.email}</Link></div>,
        },
        {
            name: 'Invitation Status',
            cell: row => row.accepted === 1 ? <span>Accepted</span> : <span>Pending</span>,
        },
        {
            name: 'Client Status',
            cell: row => row.active === 1 ? <span>Active</span> : <span>Inactive</span>,
        },
        {
            name: 'Admin Status',
            cell: row => row.admin_active === 1 ? <span>Active</span> : <span>Inactive</span>,
        }
    ]

    const {id} = props.match.params;

    const load = () => {
        let data = new FormData();
        data.append("id", id);

        setIsLoading(true);
        postData(Endpoints.DEPARTMENT_DETAIL, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setItem(res.data);
                    updateDocumentTitle(res.data.name)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    useEffect(() => {
        load()
        loadRequests(false, "business")
        loadAccounts(false)
    }, []);

    function updateStatus(number) {
        let data = new FormData();
        data.append("id", id);
        data.append("status", number);

        setIsLoading(true);
        postData(Endpoints.DEPARTMENT_UPDATE_STATUS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(SUCCESS ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);

                if (res.success === 1) {
                    load()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    const loadRequests = (loadMore, status) => {
        let data = new FormData();
        data.append("department_id", id);
        data.append("status", status);
        data.append("offset", requestsListOngoing.length);

        setIsLoading(true);

        postData(Endpoints.REQUEST_LIST_USER, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                let currentPosts = loadMore ? requestsListOngoing : [];
                currentPosts = currentPosts.concat(...res.data);
                setRequestListOngoing(currentPosts);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    const loadAccounts = (loadMore) => {
        let data = new FormData();
        data.append("department_id", id);

        setIsLoading(true);

        postData(Endpoints.CHILD_ACCOUNT_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                let currentPosts = loadMore ? businessList : [];
                currentPosts = currentPosts.concat(...res.data);
                setBusinessList(currentPosts);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };


    return (
        <AuthProvider>
            {item.name !== '' &&
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>{item.name}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-center">
                                                    {isLoading &&
                                                    <ProgressView size={23} style={{color: Colors.black, margin: 10}}/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    {!isLoading &&
                                                    <button type="submit"
                                                            className={item.admin_active === 1 ? "btn btn-danger" : "btn btn-success"}
                                                            onClick={e => updateStatus(item.admin_active === 1 ? 0 : 1)}>{item.admin_active === 1 ? "Deactivate" : "Activate"}</button>
                                                    }
                                                </div>
                                            </div>
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    <Link to={"/business/" + item.business_id}
                                                          className="btn btn-success-outline">
                                                        Back to business
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabDetails" role="tab" data-toggle="tab">
                                        Details
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabBusinesses" role="tab" data-toggle="tab">
                                        Billing accounts
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabRequests" role="tab" data-toggle="tab">
                                        Requests made
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabRates" role="tab" data-toggle="tab">
                                        Rates & Charges
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabDetails">
                                <div className="row">
                                    <div className="col-md-7">
                                        <table className="mb-3 table table-bordered table-hover table-sm dataT">
                                            <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td className="color-black-blue">
                                                    <b>{item.name}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Contact e-mail</td>
                                                <td className="color-black-blue">
                                                    <b>{item.contact_email}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Contact phone</td>
                                                <td className="color-black-blue">
                                                    <b>{item.contact_phone}</b></td>
                                            </tr>
                                            <tr>
                                                <td>This month's requests</td>
                                                <td className="color-black-blue">
                                                    <b>{item.this_months_requests}</b></td>
                                            </tr>
                                            <tr>
                                                <td>This month's bill</td>
                                                <td className="color-black-blue">
                                                    <b>{item.this_month_string}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Invoice email</td>
                                                <td className="color-black-blue">
                                                    <b>{item.invoice_email}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Monthly budget</td>
                                                <td className="color-black-blue">
                                                    <b>{item.monthly_budget_string}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Client Status</td>
                                                <td className="color-black-blue">
                                                    <b>{item.active === 0 ? "INACTIVE" : "ACTIVE"}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Admin Status</td>
                                                <td className="color-black-blue">
                                                    <b>{item.admin_active === 0 ? "INACTIVE" : "ACTIVE"}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Registered on</td>
                                                <td className="color-black-blue">
                                                    <b>{item.date_added_string}</b></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade in" id="tabRequests">
                                <DataTable
                                    columns={columns}
                                    data={requestsListOngoing}
                                    pointerOnHover={true}
                                    className={"table table-striped table-bordered"}
                                    noHeader={true}
                                    onRowClicked={e => {
                                        history.push('/request/' + e.ID);
                                    }}
                                    customStyles={Styles.customTableStyles}
                                />
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabBusinesses">
                                <DataTable
                                    columns={businessColumns}
                                    data={businessList}
                                    pointerOnHover={true}
                                    className={"table table-striped table-bordered"}
                                    noHeader={true}
                                    onRowClicked={e => {
                                        history.push('/account/' + e.ID);
                                    }}
                                    customStyles={Styles.customTableStyles}
                                />
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabRates">
                                <div className="row">
                                    <div className="col-md-8">
                                        <ListRatesComponent department={id}/>
                                    </div>
                                    <div className="col-md-4">
                                        <AddRatesComponent/>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </section>

                </div>

            </div>
            }
        </AuthProvider>
    )
};


export default DepartmentDetails