import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import {useHistory} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {updateDocumentTitle} from "../../Utils/Common";
import Map from "../Map/Map";
import ListRatesComponent from "../Views/ListRatesComponent";
import AddRatesComponent from "../Views/AddRatesComponent";

const AreaDetails = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState({title: ''});
    const [mapCentre, setMapCentre] = useState({});
    const [name, setName] = useState('');
    const [centreLat, setCentreLat] = useState(0);
    const [centreLng, setCentreLng] = useState(0);
    const [radius, setRadius] = useState(10);
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);

    const [requestTypeList, setRequestTypeList] = useState([]);
    const [requestTypeName, setRequestTypeName] = useState('');
    const [requestTypeTime, setRequestTypeTime] = useState('');
    const [requestTypeStatus, setRequestTypeStatus] = useState(1);
    const [requestTypeDes, setRequestTypeDes] = useState('');

    const [meansTypeList, setMeansTypeList] = useState([]);
    const [meansTypeName, setMeansTypeName] = useState('');
    const [meansTypeDes, setMeansTypeDes] = useState('');

    const [peakHoursList, setPeakHoursList] = useState([]);
    const [peakStart, setPeakStart] = useState('');
    const [peakEnd, setPeakEnd] = useState('');

    const [requestType, setRequestType] = useState('');
    const [meansType, setMeansType] = useState('');
    const [peakRate, setPeakRate] = useState('');
    const [normalRate, setNormalRate] = useState('');

    const [regionList, setRegionList] = useState([]);
    const [nameRegion, setNameRegion] = useState('');
    const [centreLatRegion, setCentreLatRegion] = useState('');
    const [centreLngRegion, setCentreLngRegion] = useState('');
    const [radiusRegion, setRadiusRegion] = useState('');

    const requestTypeColumns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            cell: row => <div><Link to={"/request_types/" + row.ID}>{row.name}</Link></div>,
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: true,
        },
        {
            name: 'Min hrs',
            selector: 'expected_time',
            sortable: true,
        },
        {
            name: 'Mode',
            cell: row => row.can_be_handed_over ? "Standard" : "Express",
        },
        {
            name: 'Status',
            sortable: true,
            cell: row => row.status === 1 ? <span className={"text-success"}>ACTIVE</span> :
                <span className={"text-danger"}>INACTIVE</span>,
        },
    ]

    const meansTypeColumns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            cell: row => <div><Link to={"/means/" + row.ID}>{row.name}</Link></div>,
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: true,
        },
        {
            name: 'Status',
            sortable: true,
            cell: row => row.status === 1 ? <span className={"text-success"}>ACTIVE</span> :
                <span className={"text-danger"}>INACTIVE</span>,
        },
    ]

    const peakHoursColumns = [
        {
            name: 'Start hour (H)',
            selector: 'start',
            sortable: true,
        },
        {
            name: 'End hour (H)',
            selector: 'end',
            sortable: true,
        },
        {
            name: '',
            cell: row => <button className={"btn btn-sm btn-danger"}
                                 onClick={e => deletePeakHours(row.ID)}>Delete</button>,
        },
    ]


    const regionsColumns = [
        {
            name: 'Name',
            selector: 'title',
            sortable: true,
            cell: row => <div><Link to={"/regions/" + row.ID}>{row.title}</Link></div>,
        },
        {
            name: 'Centre Lat',
            selector: 'centre_lat',
        },
        {
            name: 'Centre Lng',
            selector: 'centre_lng',
        },
        {
            name: 'Radius in KM',
            selector: 'radius',
        },
        {
            name: 'Status',
            sortable: true,
            cell: row => <div>{row.status === 1 ? <span className={"text-success"}>ACTIVE</span> :
                <span className={"text-danger"}>INACTIVE</span>}</div>,
        },
    ]

    const {id} = props.match.params;

    const load = () => {
        let data = new FormData();
        data.append("id", id);

        setIsLoading(true);
        postData(Endpoints.AREA_DETAIL, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setItem(res.data);
                    setName(res.data.title);
                    setRadius(res.data.radius);
                    setStartTime(res.data.start_time || 0)
                    setEndTime(res.data.end_time || 0)

                    setCentreLat(res.data.centre_lat);
                    setCentreLng(res.data.centre_lng);
                    setMapCentre({
                        lat: res.data.centre_lat,
                        lng: res.data.centre_lng
                    })
                    updateDocumentTitle(res.data.title)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    useEffect(() => {
        load()
        loadRequestTypes()
        loadMeansTypes()
        loadPeakHours()
        loadRegions()
    }, []);

    function updateStatus(number) {
        let data = new FormData();
        data.append("id", id);
        data.append("status", number);

        setIsLoading(true);
        postData(Endpoints.AREA_UPDATE_STATUS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    load()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function updateDetails(e) {
        e.preventDefault()

        let data = new FormData();
        data.append("id", id);
        data.append("name", name)
        data.append("centre_lat", centreLat)
        data.append("centre_lng", centreLng)
        data.append("radius", radius)
        data.append("start_time", startTime)
        data.append("end_time", endTime)

        setIsLoading(true)
        postData(Endpoints.AREA_UPDATE_DETAILS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    load()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function loadRequestTypes() {
        let data = new FormData();
        data.append("area_id", id);
        setIsLoading(true)
        postData(Endpoints.REQUEST_TYPE_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setRequestTypeList(res.data || []);
                    if (res.data.length > 0) setRequestType(res.data[0].ID)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function addRequestType(e) {
        e.preventDefault()

        let data = new FormData();
        data.append("area_id", id);
        data.append("name", requestTypeName)
        data.append("expected_time", requestTypeTime)
        data.append("status", requestTypeStatus)
        data.append("description", requestTypeDes)


        setIsLoading(true)
        postData(Endpoints.REQUEST_TYPE_ADD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    setRequestTypeName('');
                    setRequestTypeDes('')
                    loadRequestTypes()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function deleteRequestType(id) {

        let data = new FormData();
        data.append("id", id);

        setIsLoading(true)
        postData(Endpoints.REQUEST_TYPE_DELETE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    loadRequestTypes()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function loadMeansTypes() {
        let data = new FormData();
        data.append("area_id", id);
        setIsLoading(true)
        postData(Endpoints.MEANS_TYPE_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setMeansTypeList(res.data || []);
                    if (res.data.length > 0) setMeansType(res.data[0].ID)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function addMeansType(e) {
        e.preventDefault()

        let data = new FormData();
        data.append("area_id", id);
        data.append("name", meansTypeName)
        data.append("description", meansTypeDes)

        setIsLoading(true)
        postData(Endpoints.MEANS_TYPE_ADD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    setMeansTypeName('');
                    setMeansTypeDes('')
                    loadMeansTypes()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function loadPeakHours() {
        let data = new FormData();
        data.append("area_id", id);
        setIsLoading(true)
        postData(Endpoints.PEAK_HOURS_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setPeakHoursList(res.data || []);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function addPeakHours(e) {
        e.preventDefault()

        let data = new FormData();
        data.append("area_id", id);
        data.append("start", peakStart)
        data.append("end", peakEnd)

        setIsLoading(true)
        postData(Endpoints.PEAK_HOURS_ADD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    setPeakStart('');
                    setPeakEnd('');
                    loadPeakHours()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function deletePeakHours(id) {

        let data = new FormData();
        data.append("id", id);

        setIsLoading(true)
        postData(Endpoints.PEAK_HOURS_DELETE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.data, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    loadPeakHours()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function loadRegions() {
        let data = new FormData();
        data.append("area_id", id);
        setIsLoading(true)
        postData(Endpoints.REGION_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setRegionList(res.data || []);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function addRegion(e) {
        e.preventDefault()

        let data = new FormData();
        data.append("area_id", id);
        data.append("name", nameRegion)
        data.append("centre_lat", centreLatRegion)
        data.append("centre_lng", centreLngRegion)
        data.append("radius", radiusRegion)

        setIsLoading(true)
        postData(Endpoints.REGION_ADD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    setNameRegion('')
                    setCentreLatRegion('')
                    setCentreLngRegion('')
                    setRadiusRegion('')
                    loadRegions()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    return (
        <AuthProvider>
            {item.title !== '' &&
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>{item.title}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-center">
                                                    {isLoading &&
                                                    <ProgressView size={23} style={{color: Colors.black, margin: 10}}/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    {!isLoading &&
                                                    <button type="submit"
                                                            className={item.status === 1 ? "btn btn-danger" : "btn btn-success"}
                                                            onClick={e => updateStatus(item.status === 1 ? 0 : 1)}>{item.status === 1 ? "Deactivate" : "Activate"}</button>
                                                    }
                                                </div>
                                            </div>
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    <Link to="/areas"
                                                          className="btn btn-success-outline">
                                                        Back to areas
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabDetails" role="tab" data-toggle="tab">
                                        Details
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabRegions" role="tab" data-toggle="tab">
                                        Regions
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabRequests" role="tab" data-toggle="tab">
                                        Request Types
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabMeans" role="tab" data-toggle="tab">
                                        Means Types
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabPeakHours" role="tab" data-toggle="tab">
                                        Peak Hours
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabRates" role="tab" data-toggle="tab">
                                        Rates
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabDetails">
                                <div className="row">
                                    <div className="col-md-6">
                                        <table className="mb-3 table table-bordered table-hover table-sm dataT">
                                            <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td className="color-black-blue">
                                                    <b>{item.title}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Centre lat</td>
                                                <td className="color-black-blue">
                                                    <b>{item.centre_lat}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Centre lng</td>
                                                <td className="color-black-blue">
                                                    <b>{item.centre_lng}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Radius in KM</td>
                                                <td className="color-black-blue">
                                                    <b>{item.radius}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Starting working hour</td>
                                                <td className="color-black-blue">
                                                    <b>{item.start_time}H</b></td>
                                            </tr>
                                            <tr>
                                                <td>Closing working hour</td>
                                                <td className="color-black-blue">
                                                    <b>{item.end_time}H</b></td>
                                            </tr>
                                            <tr>
                                                <td>Status</td>
                                                <td className="color-black-blue">
                                                    <b>{item.status === 0 ? "INACTIVE" : "ACTIVE"}</b></td>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <Map showCircle={true} mapCentre={mapCentre} radius={item.radius}/>

                                    </div>
                                    <div className="col-md-6">
                                        <section
                                            className="box-typical box-typical-dashboard panel panel">
                                            <header className="box-typical-header panel-heading bg-dark">
                                                <h3 className="panel-title text-white">Update area</h3>
                                            </header>

                                            <div className="card-block">
                                                <form onSubmit={updateDetails}>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Name</label>
                                                        <input type="text"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Name"
                                                               value={name}
                                                               onChange={e => setName(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="form-label col-form-label-sm">Centre
                                                            latitude</label>
                                                        <input type="text"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Centre latitude"
                                                               value={centreLat}
                                                               onChange={e => setCentreLat(e.target.value)}
                                                        />
                                                    </div>


                                                    <div className="form-group">
                                                        <label className="form-label col-form-label-sm">Centre
                                                            longitude</label>
                                                        <input type="text"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Centre longitude"
                                                               value={centreLng}
                                                               onChange={e => setCentreLng(e.target.value)}
                                                        />
                                                    </div>


                                                    <div className="form-group">
                                                        <label className="form-label col-form-label-sm">Radius
                                                            in
                                                            KM</label>
                                                        <input type="number"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Radius"
                                                               value={radius}
                                                               onChange={e => setRadius(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="form-label col-form-label-sm">Working hours
                                                            (Start) - Number between 0 - 23</label>
                                                        <input type="number"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Start time"
                                                               value={startTime}
                                                               onChange={e => setStartTime(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="form-label col-form-label-sm">Working hours
                                                            (End) - Number between 0 - 23</label>
                                                        <input type="number"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="End time"
                                                               value={endTime}
                                                               onChange={e => setEndTime(e.target.value)}
                                                        />
                                                    </div>


                                                    <div className="form-group">
                                                        {isLoading ?
                                                            <ProgressView size={23}
                                                                          style={{
                                                                              color: Colors.black,
                                                                              marginTop: 5
                                                                          }}/> :
                                                            <button type="submit" className="btn btn-primary"
                                                            >Update</button>
                                                        }
                                                    </div>

                                                </form>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade in" id="tabRequests">
                                <div className="row">
                                    <div className="col-md-6">
                                        <DataTable
                                            columns={requestTypeColumns}
                                            data={requestTypeList}
                                            pointerOnHover={true}
                                            className={"table table-striped table-bordered"}
                                            noHeader={true}
                                            customStyles={Styles.customTableStyles}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <section
                                            className="box-typical box-typical-dashboard panel panel">
                                            <header className="box-typical-header panel-heading bg-dark">
                                                <h3 className="panel-title text-white">Add new request type</h3>
                                            </header>

                                            <div className="card-block">
                                                <form onSubmit={addRequestType}>
                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Name</label>
                                                        <input type="text"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Name"
                                                               value={requestTypeName}
                                                               onChange={e => setRequestTypeName(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Description</label>
                                                        <input type="text"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Name"
                                                               value={requestTypeDes}
                                                               onChange={e => setRequestTypeDes(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Expected duration
                                                            in hours</label>
                                                        <input type="number"
                                                               required={true}
                                                               className="form-control"
                                                               value={requestTypeTime}
                                                               onChange={e => setRequestTypeTime(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Select a
                                                            mode</label>
                                                        <select className="form-control"
                                                                value={requestTypeStatus}
                                                                onChange={({target}) => setRequestTypeStatus(target.value)}>
                                                            <option
                                                                value={1}>Standard mode - Riders can hand over requests
                                                                to each other
                                                            </option>
                                                            <option
                                                                value={0}>Express mode - Only one rider is allowed to
                                                                handle each
                                                                request
                                                            </option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group">
                                                        {isLoading ?
                                                            <ProgressView size={23}
                                                                          style={{
                                                                              color: Colors.black,
                                                                              marginTop: 5
                                                                          }}/> :
                                                            <button type="submit" className="btn btn-primary"
                                                            >Add</button>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade in" id="tabMeans">
                                <div className="row">
                                    <div className="col-md-6">
                                        <DataTable
                                            columns={meansTypeColumns}
                                            data={meansTypeList}
                                            pointerOnHover={true}
                                            className={"table table-striped table-bordered"}
                                            noHeader={true}
                                            customStyles={Styles.customTableStyles}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <section
                                            className="box-typical box-typical-dashboard panel panel">
                                            <header className="box-typical-header panel-heading bg-dark">
                                                <h3 className="panel-title text-white">Add new means type</h3>
                                            </header>

                                            <div className="card-block">
                                                <form onSubmit={addMeansType}>
                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Name</label>
                                                        <input type="text"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Name"
                                                               value={meansTypeName}
                                                               onChange={e => setMeansTypeName(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Description</label>
                                                        <input type="text"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Name"
                                                               value={meansTypeDes}
                                                               onChange={e => setMeansTypeDes(e.target.value)}
                                                        />
                                                    </div>


                                                    <div className="form-group">
                                                        {isLoading ?
                                                            <ProgressView size={23}
                                                                          style={{
                                                                              color: Colors.black,
                                                                              marginTop: 5
                                                                          }}/> :
                                                            <button type="submit" className="btn btn-primary"
                                                            >Add</button>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade in" id="tabPeakHours">
                                <div className="row">
                                    <div className="col-md-6">
                                        <DataTable
                                            columns={peakHoursColumns}
                                            data={peakHoursList}
                                            pointerOnHover={true}
                                            className={"table table-striped table-bordered"}
                                            noHeader={true}
                                            customStyles={Styles.customTableStyles}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <section
                                            className="box-typical box-typical-dashboard panel panel">
                                            <header className="box-typical-header panel-heading bg-dark">
                                                <h3 className="panel-title text-white">Add new peak hour</h3>
                                            </header>

                                            <div className="card-block">
                                                <form onSubmit={addPeakHours}>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Start
                                                            hour (24hr)</label>
                                                        <input type="number"
                                                               max={23}
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Start"
                                                               value={peakStart}
                                                               onChange={e => setPeakStart(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">End
                                                            hour (24hr)</label>
                                                        <input type="number"
                                                               required={true}
                                                               max={23}
                                                               className="form-control"
                                                               placeholder="End"
                                                               value={peakEnd}
                                                               onChange={e => setPeakEnd(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        {isLoading ?
                                                            <ProgressView size={23}
                                                                          style={{
                                                                              color: Colors.black,
                                                                              marginTop: 5
                                                                          }}/> :
                                                            <button type="submit" className="btn btn-primary"
                                                            >Add</button>
                                                        }
                                                    </div>

                                                </form>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade in" id="tabRates">
                                <div className="row">
                                    <div className="col-md-8">
                                        <ListRatesComponent area={id}/>
                                    </div>
                                    <div className="col-md-4">
                                        <AddRatesComponent area={id}/>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade in" id="tabRegions">
                                <div className="row">
                                    <div className="col-md-8">
                                        <DataTable
                                            columns={regionsColumns}
                                            data={regionList}
                                            pointerOnHover={true}
                                            className={"table table-striped table-bordered"}
                                            noHeader={true}
                                            customStyles={Styles.customTableStyles}
                                            onRowClicked={e => {
                                                history.push('/regions/' + e.ID);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <section
                                            className="box-typical box-typical-dashboard panel panel">
                                            <header className="box-typical-header panel-heading bg-dark">
                                                <h3 className="panel-title text-white">Add new region</h3>
                                            </header>

                                            <div className="card-block">
                                                <form onSubmit={addRegion}>
                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Name</label>
                                                        <input type="text"
                                                               required
                                                               className="form-control"
                                                               placeholder="Name"
                                                               value={nameRegion}
                                                               onChange={e => setNameRegion(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="form-label col-form-label-sm">Centre
                                                            latitude</label>
                                                        <input type="text"
                                                               required
                                                               className="form-control"
                                                               placeholder="Centre latitude"
                                                               value={centreLatRegion}
                                                               onChange={e => setCentreLatRegion(e.target.value)}
                                                        />
                                                    </div>


                                                    <div className="form-group">
                                                        <label className="form-label col-form-label-sm">Centre
                                                            longitude</label>
                                                        <input type="text"
                                                               required
                                                               className="form-control"
                                                               placeholder="Centre longitude"
                                                               value={centreLngRegion}
                                                               onChange={e => setCentreLngRegion(e.target.value)}
                                                        />
                                                    </div>


                                                    <div className="form-group">
                                                        <label className="form-label col-form-label-sm">Radius
                                                            in KM</label>
                                                        <input type="text"
                                                               required
                                                               className="form-control"
                                                               placeholder="Radius"
                                                               value={radiusRegion}
                                                               onChange={e => setRadiusRegion(e.target.value)}
                                                        />
                                                    </div>


                                                    <div className="form-group">
                                                        {isLoading ?
                                                            <ProgressView size={23}
                                                                          style={{
                                                                              color: Colors.black,
                                                                              marginTop: 5
                                                                          }}/> :
                                                            <button type="submit" className="btn btn-primary"
                                                            >Add</button>
                                                        }
                                                    </div>

                                                </form>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                </div>

            </div>
            }
        </AuthProvider>
    )
};


export default AreaDetails