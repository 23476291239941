import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import {useHistory} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {goToPage, updateDocumentTitle} from "../../Utils/Common";
import Map from "../Map/Map";

const RequestDetails = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState({title: ''});
    const [cancellationReason, setCancellationReason] = useState('');

    const [deliveryType, setDeliveryType] = useState('');
    const [requestTypeList, setRequestTypeList] = useState([]);

    const [regionList, setRegionList] = useState([]);
    const [pickUpRegion, setPickUpRegion] = useState('');
    const [dropOffRegion, setDropOffRegion] = useState('');

    const [riderList, setRiderList] = useState([]);
    const [riderId, setRiderId] = useState('');
    const [riderRole, setRiderRole] = useState('rider_one');


    const {id} = props.match.params;

    const load = (loadExtras = false) => {
        let data = new FormData();
        data.append("id", id);

        setIsLoading(true);
        postData(Endpoints.REQUEST_DETAIL, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setItem(res.data);
                    updateDocumentTitle(res.data.request_code)
                    setDeliveryType(res.data.delivery_type);
                    setPickUpRegion(res.data.region_pick_up);
                    setDropOffRegion(res.data.region_drop_id);
                    setRiderId(res.data.rider_id);
                    if (loadExtras) {
                        loadRequestTypes(res.data.area_id)
                        loadRegions(res.data.area_id)
                        loadRiders(res.data.rider_id)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    useEffect(() => {
        load(true)
    }, []);

    function loadRequestTypes(areaId) {
        let data = new FormData();
        data.append("area_id", areaId);
        setIsLoading(true)
        postData(Endpoints.REQUEST_TYPE_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setRequestTypeList(res.data || []);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function loadRegions(areaId) {
        let data = new FormData();
        data.append("area_id", areaId);
        data.append("status", 1);
        setIsLoading(true)
        postData(Endpoints.REGION_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setRegionList(res.data || []);
                    if (res.data.length > 0) {
                        setPickUpRegion(res.data[0].ID);
                        setDropOffRegion(res.data[0].ID);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    const loadRiders = (selectedRider) => {
        let data = new FormData();
        data.append("status", 1);
        setIsLoading(true);
        postData(Endpoints.RIDER_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setRiderList(res.data || []);
                    if (res.data.length > 0 && parseInt(selectedRider) === 0) {
                        setRiderId(res.data[0].ID);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    const updateDeliveryType = e => {
        e.preventDefault();

        let data = new FormData();
        data.append("delivery_type", deliveryType)
        data.append("id", item.ID)

        setIsLoading(true);

        postData(Endpoints.REQUEST_UPDATE_DELIVERY, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    Notify(res.data, SUCCESS);
                    load()
                } else {
                    if (Array.isArray(res.data)) {
                        Notify(res.data[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    const assignRegion = e => {
        e.preventDefault();

        let data = new FormData();
        data.append("region_drop", dropOffRegion)
        data.append("region_pick_up", pickUpRegion)
        data.append("id", item.ID)

        setIsLoading(true);

        postData(Endpoints.REQUEST_ASSIGN_REGION, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    Notify(res.data, SUCCESS);
                    load()
                } else {
                    if (Array.isArray(res.data)) {
                        Notify(res.data[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    const assignRider = e => {
        e.preventDefault();

        let data = new FormData();
        data.append("role", riderRole)
        data.append("rider_id", riderId)
        data.append("id", item.ID)

        setIsLoading(true);

        postData(Endpoints.REQUEST_ASSIGN_RIDER, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    Notify(res.data, SUCCESS);
                    load()
                } else {
                    if (Array.isArray(res.data)) {
                        Notify(res.data[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    const cancelRequest = e => {
        e.preventDefault();

        let data = new FormData();
        data.append("reason", cancellationReason)
        data.append("id", item.ID)

        setIsLoading(true);

        postData(Endpoints.REQUEST_CANCEL, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    Notify(res.data, SUCCESS);
                    load()
                } else {
                    if (Array.isArray(res.data)) {
                        Notify(res.data[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    return (
        <AuthProvider>
            {item.title !== '' &&
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h6 style={{fontWeight: 700}}>#{item.request_code}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-center">
                                                    {isLoading &&
                                                    <ProgressView size={23} style={{color: Colors.black, margin: 10}}/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-left mr-2">
                                                    <h6 style={{fontWeight: 700}}>{item.status}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    <Link to="/requests"
                                                          className="btn btn-success-outline">
                                                        Back to requests
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabDetails" role="tab" data-toggle="tab">
                                        Overview
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabSender" role="tab" data-toggle="tab">
                                        Sender / From
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabDropOffs" role="tab" data-toggle="tab">
                                        Drop offs
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabRiders" role="tab" data-toggle="tab">
                                        Riders
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabTracking" role="tab" data-toggle="tab">
                                        Tracking
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabDetails">
                                <div className="row">
                                    <div className="col-md-6">
                                        <table className="mb-3 table table-bordered table-hover table-sm dataT">
                                            <tbody>
                                            <tr>
                                                <td>Area</td>
                                                <td className="color-black-blue">
                                                    <b>{item.area.title}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Pick up location</td>
                                                <td className="color-black-blue">
                                                    <b>{item.pick_up_location}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Delivery Type</td>
                                                <td className="color-black-blue"><b>{item.delivery_type}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Means</td>
                                                <td className="color-black-blue"><b>{item.means}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Pick Up Type</td>
                                                <td className="color-black-blue"><b>{item.pick_up_type}</b></td>
                                            </tr>
                                            {item.is_scheduled === 1 &&
                                            <tr>
                                                <td>Pick Up Time</td>
                                                <td className="color-black-blue">
                                                    <b>{item.pick_up_date_time_string}</b></td>
                                            </tr>
                                            }
                                            <tr>
                                                <td>Expected Completion Time</td>
                                                <td className="color-black-blue">
                                                    <b>{item.expected_end_time_string}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Request Status</td>
                                                <td className="color-black-blue"><b>{item.status}</b></td>
                                            </tr>

                                            <tr>
                                                <td>Assigned R1 rider</td>
                                                <td className="color-black-blue">
                                                    <b> {item.rider_details.name}</b></td>
                                            </tr>

                                            {item.status === "CANCELLED" &&
                                            <tr>
                                                <td>Cancellation Reason</td>
                                                <td className="color-black-blue"><b>{item.cancellation_reason}</b>
                                                </td>
                                            </tr>
                                            }

                                            <tr>
                                                <td>Progress Status</td>
                                                <td className="color-black-blue"><b>{item.progress_status}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Perf. Status</td>
                                                <td className="color-black-blue">
                                                    <b>{item.performance_status}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Total Distance</td>
                                                <td className="color-black-blue"><b>{item.distance_km} Km</b></td>
                                            </tr>

                                            {item.region_pick_up > 0 &&
                                            <tr>
                                                <td>Pick Up Region</td>
                                                <td className="color-black-blue">
                                                    <b>{item.pick_up_region_details.title}</b></td>
                                            </tr>
                                            }

                                            {item.region_drop_id > 0 &&
                                            <tr>
                                                <td>Drop Off Region</td>
                                                <td className="color-black-blue">
                                                    <b>{item.drop_off_region_details.title}</b></td>
                                            </tr>
                                            }

                                            <tr>
                                                <td>Fee</td>
                                                <td className="color-black-blue"><b>KES {item.fee}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Payment Status</td>
                                                <td className="color-black-blue"><b>{item.payment_status}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Payment Method</td>
                                                <td className="color-black-blue"><b>{item.payment_method}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Rave Payment Method</td>
                                                <td className="color-black-blue"><b>{item.rave_payment_method}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Transaction ID</td>
                                                <td className="color-black-blue"><b>{item.transaction_id}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Promo Code</td>
                                                <td className="color-black-blue"><b>{item.promo_code}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Discount Applied</td>
                                                <td className="color-black-blue"><b>KES {item.promo_code_discount}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Rider Two Code</td>
                                                <td className="color-black-blue"><b>{item.code_rider_two}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Call Centre Code</td>
                                                <td className="color-black-blue"><b>{item.code_call_centre}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Rider Three Code</td>
                                                <td className="color-black-blue"><b>{item.code_rider_three}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Rider Four Code</td>
                                                <td className="color-black-blue"><b>{item.code_rider_four}</b></td>
                                            </tr>
                                            <tr>
                                                <td>From Corporate</td>
                                                <td className="color-black-blue">
                                                    <b> {item.corporate_id > 0 ? "YES" : "NO"}</b>
                                                </td>
                                            </tr>
                                            {item.corporate_id > 0 ?
                                                <div>
                                                    <tr>
                                                        <td>Business</td>
                                                        <td className="color-black-blue">
                                                            <b><Link
                                                                to={"/business/" + item.corporate_id}>{item.business_details.name}</Link></b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Department</td>
                                                        <td className="color-black-blue">
                                                            <b><Link
                                                                to={"/department/" + item.department_id}>{item.department_details.name}</Link></b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Billed to</td>
                                                        <td className="color-black-blue">
                                                            <b><Link
                                                                to={"/account/" + item.child_account_id}>{item.child_account_details.email}</Link></b>
                                                        </td>
                                                    </tr>
                                                </div>
                                                : <tr>
                                                    <td>Billed to</td>
                                                    <td className="color-black-blue">
                                                        <b>{item.user_details.email}</b>
                                                    </td>
                                                </tr>}


                                            <tr>
                                                <td>Promo Code</td>
                                                <td className="color-black-blue"><b>{item.promo_code}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Promo Discount</td>
                                                <td className="color-black-blue"><b>{item.promo_discount}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Applied Referral Discount</td>
                                                <td className="color-black-blue">
                                                    <b>{item.applied_referral_discount === 1 ? "YES" : "NO"}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Fines</td>
                                                <td className="color-black-blue"><b>KES {item.fines}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Night charges applied</td>
                                                <td className="color-black-blue">
                                                    <b>{item.night_charges_applied === 1 ? "YES" : "NO"}</b></td>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <table className="mb-3 table table-bordered table-hover table-sm dataT">
                                            <tbody>
                                            <tr>
                                                <td>Customer Feedback</td>
                                                <td className="color-black-blue">
                                                    <b>{item.customer_feedback}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Customer Rating</td>
                                                <td className="color-black-blue">
                                                    <b>{item.customer_rating}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Rider Review</td>
                                                <td className="color-black-blue">
                                                    <b>{item.rider_review}</b></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-6">

                                        {item.status !== "CANCELLED" &&
                                        <div>
                                            <section
                                                className="box-typical box-typical-dashboard panel panel">
                                                <header className="box-typical-header panel-heading bg-dark">
                                                    <h3 className="panel-title text-white">Assign rider</h3>
                                                </header>

                                                <div className="card-block">
                                                    <form onSubmit={assignRider}>


                                                        <div className="form-group">
                                                            <label className="form-label col-form-label-sm">Select
                                                                role</label>
                                                            <select
                                                                required={true}
                                                                className="form-control"
                                                                value={riderRole}
                                                                onChange={e => {
                                                                    setRiderRole(e.target.value)
                                                                }}
                                                            >
                                                                <option value="rider_one">Rider One (R1)</option>
                                                                <option value="rider_two">Rider Two (R2)</option>
                                                                <option value="rider_three">Rider Three (R3)</option>
                                                                <option value="rider_four">Rider Four (R4)</option>
                                                            </select>
                                                        </div>


                                                        <div className="form-group">
                                                            <label className="form-label col-form-label-sm">Select
                                                                rider</label>
                                                            <select
                                                                required={true}
                                                                className="form-control"
                                                                value={riderId}
                                                                onChange={e => {
                                                                    setRiderId(e.target.value)
                                                                }}
                                                            >
                                                                {riderList.map((i, k) => <option
                                                                    value={i.ID}>{i.name} - {i.ongoing_jobs} ongoing
                                                                    jobs</option>
                                                                )}
                                                            </select>
                                                        </div>


                                                        <div className="form-group">
                                                            {isLoading ?
                                                                <ProgressView size={23}
                                                                              style={{
                                                                                  color: Colors.black,
                                                                                  marginTop: 5
                                                                              }}/> :
                                                                <button type="submit" className="btn btn-primary"
                                                                >Assign</button>
                                                            }
                                                        </div>

                                                    </form>
                                                </div>
                                            </section>

                                            <section
                                                className="box-typical box-typical-dashboard panel panel">
                                                <header className="box-typical-header panel-heading bg-dark">
                                                    <h3 className="panel-title text-white">Update region</h3>
                                                </header>

                                                <div className="card-block">
                                                    <form onSubmit={assignRegion}>

                                                        <div className="form-group">
                                                            <label className="form-label col-form-label-sm">Pick up
                                                                region</label>
                                                            <select
                                                                required={true}
                                                                className="form-control"
                                                                value={pickUpRegion}
                                                                onChange={e => {
                                                                    setPickUpRegion(e.target.value)
                                                                }}
                                                            >
                                                                {regionList.map((i, k) => <option
                                                                    value={i.ID}
                                                                    selected={i.ID === item.region_pick_up}>{i.title}</option>
                                                                )}
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="form-label col-form-label-sm">Drop off
                                                                region</label>
                                                            <select
                                                                required={true}
                                                                className="form-control"
                                                                value={dropOffRegion}
                                                                onChange={e => setDropOffRegion(e.target.value)}
                                                            >
                                                                {regionList.map((i, k) => <option
                                                                    value={i.ID}
                                                                    selected={i.ID === item.region_drop_id}>{i.title}</option>
                                                                )}
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            {isLoading ?
                                                                <ProgressView size={23}
                                                                              style={{
                                                                                  color: Colors.black,
                                                                                  marginTop: 5
                                                                              }}/> :
                                                                <button type="submit" className="btn btn-primary"
                                                                >Update</button>
                                                            }
                                                        </div>

                                                    </form>
                                                </div>
                                            </section>

                                            <section
                                                className="box-typical box-typical-dashboard panel panel">
                                                <header className="box-typical-header panel-heading bg-dark">
                                                    <h3 className="panel-title text-white">Update delivery type</h3>
                                                </header>

                                                <div className="card-block">
                                                    <form onSubmit={updateDeliveryType}>
                                                        <div className="form-group">
                                                            <label className="form-label col-form-label-sm">Select
                                                                delivery
                                                                type</label>
                                                            <select
                                                                required={true}
                                                                className="form-control"
                                                                placeholder="Name"
                                                                value={deliveryType}
                                                                onChange={e => setDeliveryType(e.target.value)}
                                                            >
                                                                {requestTypeList.map((i, k) => <option
                                                                    value={i.name}
                                                                    selected={i.name === item.delivery_type}>{i.name}</option>
                                                                )}
                                                            </select>
                                                        </div>


                                                        <div className="form-group">
                                                            {isLoading ?
                                                                <ProgressView size={23}
                                                                              style={{
                                                                                  color: Colors.black,
                                                                                  marginTop: 5
                                                                              }}/> :
                                                                <button type="submit" className="btn btn-primary"
                                                                >Update</button>
                                                            }
                                                        </div>

                                                    </form>
                                                </div>
                                            </section>

                                            <section
                                                className="box-typical box-typical-dashboard panel panel">
                                                <header className="box-typical-header panel-heading bg-danger">
                                                    <h3 className="panel-title text-white">Cancel request</h3>
                                                </header>

                                                <div className="card-block">
                                                    <form onSubmit={cancelRequest}>

                                                        <div className="form-group">
                                                            <label className="form-label col-form-label-sm">Cancellation
                                                                reason</label>
                                                            <textarea
                                                                required={true}
                                                                className="form-control"
                                                                placeholder="Enter cancellation reason"
                                                                value={cancellationReason}
                                                                onChange={e => setCancellationReason(e.target.value)}
                                                            />
                                                        </div>

                                                        <div className="form-group">
                                                            {isLoading ?
                                                                <ProgressView size={23}
                                                                              style={{
                                                                                  color: Colors.black,
                                                                                  marginTop: 5
                                                                              }}/> :
                                                                <button type="submit" className="btn btn-danger"
                                                                >Cancel</button>
                                                            }
                                                        </div>

                                                    </form>
                                                </div>
                                            </section>
                                        </div>
                                        }


                                    </div>
                                </div>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabSender">
                                <div className="row">
                                    <div className="col-md-4">
                                        <table className="mb-3 table table-bordered table-hover table-sm dataT">
                                            <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td className="color-black-blue">
                                                    <b><Link
                                                        to={"/client/" + item.user_id}>{item.user_details.name}</Link></b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Phone</td>
                                                <td className="color-black-blue"><b>{item.user_details.phone}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td className="color-black-blue"><b>{item.user_details.email}</b></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabDropOffs">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table id="table" className="display table table-striped table-bordered"
                                               cellSpacing="0"
                                               width="100%">
                                            <thead>
                                            <tr>
                                                <th>Address</th>
                                                <th>Phone Number</th>
                                                <th>Notes</th>
                                                <th>Status</th>
                                                <th>Receiving Code</th>
                                                <th>Client Sign.</th>
                                                <th>Rider Sign.</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {item.drop_offs.map((i, k) => <tr>
                                                <td>{i.address}</td>
                                                <td>{i.phone}</td>
                                                <td>{i.notes}</td>
                                                <td>{i.status}</td>
                                                <td>{i.receiving_code}</td>
                                                <td>
                                                    {i.client_signature === "" ? "-" : <img
                                                        src={Endpoints.SERVER_HOST + Endpoints.FILE_URL + i.client_signature}
                                                        style={{height: 90}}/>}
                                                </td>
                                                <td>
                                                    {i.rider_signature === "" ? "-" : <img
                                                        src={Endpoints.SERVER_HOST + Endpoints.FILE_URL + i.rider_signature}
                                                        style={{height: 90}}/>}
                                                </td>
                                            </tr>)}

                                            </tbody>
                                        </table>


                                    </div>
                                </div>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabRiders">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table id="table" className="display table table-striped table-bordered"
                                               cellSpacing="0"
                                               width="100%">
                                            <thead>
                                            <tr>
                                                <th>Rider</th>
                                                <th>Role</th>
                                                <th>Code</th>
                                                <th>Status</th>
                                                <th>Receiving Details</th>
                                                <th>Handing Over Details</th>
                                            </tr>
                                            </thead>
                                            {item.involved_riders &&
                                            <tbody>
                                            {item.involved_riders.map((i, k) => <tr>
                                                    <td><Link to={"/riders/" + i.rider_id}>{i.rider_details.name}</Link>
                                                    </td>
                                                    <td>{i.role}</td>
                                                    <td>{i.code}</td>
                                                    <td>{i.status}</td>
                                                    <td>
                                                        <b>Time</b> : {i.accepting_time_string}<br/>
                                                        <b>Location</b> : {i.accepting_location}<br/>
                                                        <b>LatLng</b> : {i.accepting_lat}, {i.accepting_lng}<br/><br/>
                                                        {i.accepting_signature === "" ? "-" : <img
                                                            src={Endpoints.SERVER_HOST + Endpoints.FILE_URL + i.accepting_signature}
                                                            style={{height: 90}}/>}
                                                    </td>
                                                    <td>
                                                        {i.status !== "PENDING" &&
                                                        <div>
                                                            <b>Time</b> : {i.handing_time_string}<br/>
                                                            <b>Location</b> : {i.handing_location}<br/>
                                                            <b>LatLng</b> : {i.handing_lat}, {i.handing_lng}<br/><br/>
                                                            {i.handing_signature === "" ? "-" : <img
                                                                src={Endpoints.SERVER_HOST + Endpoints.FILE_URL + i.handing_signature}
                                                                style={{height: 90}}/>}
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                            }

                                        </table>

                                    </div>
                                </div>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabTracking">
                                <div className="row">
                                    <div className="col-md-12">
                                        <section className="widget widget-activity">
                                            {item.tracking &&
                                            <div>
                                                {item.tracking.map((i, k) =>
                                                    <div className="widget-activity-item">
                                                        <div className="user-card-row">
                                                            <div className="tbl-row">
                                                                <div className="tbl-cell tbl-cell-photo">
                                                                    <a href="#">
                                                                        <i className="fa fa-truck fa-2x"></i>
                                                                    </a>
                                                                </div>
                                                                <div className="tbl-cell">
                                                                    <p style={{
                                                                        color: Colors.black,
                                                                        fontSize: 16,
                                                                        fontStyle: 'italic'
                                                                    }}>
                                                                        {i.text}
                                                                    </p>
                                                                    <p style={{fontSize: 14}}>{i.date_added_string}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            }
                                        </section>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                </div>

            </div>
            }
        </AuthProvider>
    )
}
;

export default RequestDetails