import React, {useEffect, useState} from "react";
import Colors from "../../Constants/Colors";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import ProgressView from "./ProgressView";
import {useGlobal} from "../../Store";

const AddRatesComponent = ({area = "0", business = "0", department = "0"}) => {
    const [globalState, globalActions] = useGlobal()

    const [requestType, setRequestType] = useState('');
    const [meansType, setMeansType] = useState('');
    const [peakRate, setPeakRate] = useState('');
    const [normalRate, setNormalRate] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [requestTypeList, setRequestTypeList] = useState([]);
    const [meansTypeList, setMeansTypeList] = useState([]);

    useEffect(() => {
        loadMeansTypes()
        loadRequestTypes()
    }, []);


    function addRates(e) {
        e.preventDefault()

        let data = new FormData();
        data.append("business_id", business);
        data.append("department_id", department);
        data.append("area_id", area);
        data.append("request_type", requestType)
        data.append("means_type", meansType)
        data.append("normal_rate", normalRate)
        data.append("peak_rate", peakRate)

        setIsLoading(true)
        postData(Endpoints.RATE_ADD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    setNormalRate('')
                    setPeakRate('')
                    globalActions.setObservableData({
                        ...globalState.observableData,
                        rates: !globalState.observableData.rates
                    })
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function loadRequestTypes() {
        let data = new FormData();
        data.append("status", 1)
        data.append("area_id", area);

        setIsLoading(true)
        postData(Endpoints.REQUEST_TYPE_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setRequestTypeList(res.data || []);
                    if (res.data.length > 0) setRequestType(res.data[0].ID)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function loadMeansTypes() {
        let data = new FormData();
        data.append("status", 1)
        data.append("area_id", area);

        setIsLoading(true)
        postData(Endpoints.MEANS_TYPE_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setMeansTypeList(res.data || []);
                    if (res.data.length > 0) setMeansType(res.data[0].ID)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    return (
        <section
            className="box-typical box-typical-dashboard panel panel">
            <header className="box-typical-header panel-heading bg-dark">
                <h3 className="panel-title text-white">Add new rate</h3>
            </header>

            <div className="card-block">
                <form onSubmit={addRates}>
                    <div className="form-group">
                        <label
                            className="form-label col-form-label-sm">Select
                            request type</label>
                        <select className="form-control"
                                value={requestType}
                                onChange={({target}) => setRequestType(target.value)}>
                            {requestTypeList.map((i, k) => <option
                                value={i.ID}>{i.name}</option>
                            )}
                        </select>
                    </div>

                    <div className="form-group">
                        <label
                            className="form-label col-form-label-sm">Select
                            means type</label>
                        <select className="form-control"
                                value={meansType}
                                onChange={({target}) => setMeansType(target.value)}>
                            {meansTypeList.map((i, k) => <option
                                value={i.ID}>{i.name}</option>
                            )}
                        </select>
                    </div>

                    <div className="form-group">
                        <label
                            className="form-label col-form-label-sm">Normal rate
                            per KM in KES</label>
                        <input type="number"
                               required={true}
                               className="form-control"
                               value={normalRate}
                               onChange={e => setNormalRate(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label
                            className="form-label col-form-label-sm">Peak rate
                            per KM in KES</label>
                        <input type="number"
                               required={true}
                               className="form-control"
                               value={peakRate}
                               onChange={e => setPeakRate(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        {isLoading ?
                            <ProgressView size={23}
                                          style={{
                                              color: Colors.black,
                                              marginTop: 5
                                          }}/> :
                            <button type="submit" className="btn btn-primary"
                            >Add</button>
                        }
                    </div>
                </form>
            </div>
        </section>
    )
};

export default AddRatesComponent;