import useGlobalHook from "use-global-hook";
import React from "react";

const initialState = {
    currentUser: {},
    userLoggedIn: false,
    userCheckComplete: false,
    observableData: {
        rates: false,
    }
};

export const actions = {
    setProfileMenu: (store, items) => {
        store.setState({profileMenu: items});
    },
    setSiteData: (store, data) => {
        store.setState({siteData: data});
    },
    setCurrentUser: (store, user) => {
        store.setState({currentUser: user});
    },
    setUserLoggedIn: (store, loggedIn) => {
        store.setState({userLoggedIn: loggedIn});
    },
    setUserCheckComplete: (store, complete) => {
        store.setState({userCheckComplete: complete});
    },
    setObservableData: (store, data) => {
        store.setState({observableData: data});
    },
};

export const useGlobal = useGlobalHook(React, initialState, actions);
