import React, {useEffect, useState} from "react";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {useGlobal} from "../../Store";
import Colors from "../../Constants/Colors";
import ProgressView from "./ProgressView";

const ListRatesComponent = ({area = "", business = "", department = ""}) => {


    const [globalState, globalActions] = useGlobal()
    const [ratesList, setRatesList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentRate, setCurrentRate] = useState({});

    useEffect(() => {
        loadRates()
    }, [globalState.observableData.rates])

    function loadRates() {
        let data = new FormData();
        data.append("area_id", area);
        data.append("business_id", business);
        data.append("department_id", department);

        setIsLoading(true)
        postData(Endpoints.RATE_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setRatesList(res.data.sort((a, b) => {
                        if (a.request_type < b.request_type) {
                            return -1;
                        }
                        if (a.request_type > b.request_type) {
                            return 1;
                        }
                        return 0;
                    }) || []);
                }
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }


    function deleteRate(id) {

        let data = new FormData();
        data.append("id", id);

        setIsLoading(true)
        postData(Endpoints.RATE_DELETE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    loadRates()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function updateRate(id, normalRate, peakRate) {

        let data = new FormData();
        data.append("id", id);
        data.append("normal_rate", normalRate)
        data.append("peak_rate", peakRate)
        setIsLoading(true)
        postData(Endpoints.RATE_UPDATE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    loadRates()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    return (
        <div>
            {ratesList.map((a, b) =>
                <section className="box-typical box-typical-dashboard panel panel">
                    <header className="box-typical-header panel-heading bg-blue"
                            style={{backgroundColor: Colors.green_500}}>
                        <h3 className="panel-title text-white">{a.request_type} | {a.means_type}</h3>
                    </header>
                    <div className="panel-body">
                        <div className={"p-2"}>
                            <div className="form-group">
                                <label
                                    className="form-label col-form-label-sm">Normal rate
                                    per KM in KES</label>
                                <input type="number"
                                       required={true}
                                       className="form-control"
                                       value={a.normal_rate}
                                       onChange={e => {
                                           let list = ratesList
                                           list[b] = {...a, normal_rate: e.target.value}
                                           setRatesList([...list])
                                       }}
                                />
                            </div>

                            <div className="form-group">
                                <label
                                    className="form-label col-form-label-sm">Peak rate
                                    per KM in KES</label>
                                <input type="number"
                                       required={true}
                                       className="form-control"
                                       value={a.peak_rate}
                                       onChange={e => {
                                           let list = ratesList
                                           list[b] = {...a, peak_rate: e.target.value}
                                           setRatesList([...list])
                                       }}
                                />
                            </div>

                            {isLoading ?
                                <ProgressView size={23}
                                              style={{
                                                  color: Colors.black,
                                                  marginTop: 5
                                              }}/> :
                                <button type="submit" className="btn btn-primary"
                                        onClick={e => updateRate(a.ID, ratesList[b].normal_rate, ratesList[b].peak_rate)}
                                >Update</button>
                            }

                            <button className={"btn btn-sm btn-danger float-right"}
                                    onClick={e => deleteRate(a.ID)}>Delete
                            </button>

                        </div>
                    </div>
                </section>
            )}
        </div>
    )
};

export default ListRatesComponent;